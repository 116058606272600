
import { defineComponent } from "vue";
import CodeHighlighter from "@/view/content/highlighters/CodeHighlighter.vue";

export default defineComponent({
  name: "icon-sizes",
  components: {
    CodeHighlighter
  },
  setup() {
    const sizes = [
      "5x",
      "4x",
      "3x",
      "2tx",
      "2hx",
      "2qx",
      "2x",
      "1",
      "2",
      "3",
      "5",
      "6"
    ];

    return {
      sizes
    };
  }
});
